import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, FreeMode, Keyboard, A11y } from 'swiper/modules';
import { animateFade } from '../../../assets/src/js/includes/animations';

const blocks = document.querySelectorAll(
    `.wp-block-blufish-testimonials-slider`
);

if (blocks.length > 0) {
    blocks.forEach((block) => {

        const slider = block.querySelector(`.js-swiper`);
        const prev = block.querySelector(`.swiper-button-prev`);
        const next = block.querySelector(`.swiper-button-next`);
        const pagination = block.querySelector(`.swiper-pagination`);

        new Swiper(slider, {
            modules: [A11y, Autoplay, Navigation, Pagination, FreeMode, Keyboard],
            speed: 100,
            rewind: true,
            grabCursor: true,
            slidesPerView: 1,
            autoHeight: true,
            a11y: {
                enabled: true
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            keyboard: {
                enabled: true,
            },
            navigation: {
                enabled: true,
                prevEl: prev,
                nextEl: next,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
            },
            on: {
                afterInit: () => ScrollTrigger.refresh(),
                slideChangeTransitionEnd: () => ScrollTrigger.refresh(),
                update: () => ScrollTrigger.refresh(),
            }
        });

        const testimonials = block.querySelector(`.js-testimonials`);
        animateFade(testimonials);
    });
}
